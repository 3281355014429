export const ptBr = {
  opens: "right",
  autoUpdateInput: true,
  cancelButtonClasses: "btn-primary",
  applyButtonClasses: "btn-success",
  startDate: moment().subtract(1, "days"),
  endDate: moment(),
  ranges: {
    Hoje: [moment(), moment()],
    Ontem: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Últimos 7 dias": [moment().subtract(6, "days"), moment()],
    "Últimos 30 dias": [moment().subtract(29, "days"), moment()],
    "Último ano": [moment().subtract(1, "year"), moment()],
    "Próximo ano": [moment(), moment().add(1, "year")],
    "Próximo mês": [moment(), moment().add(1, "month")],
    "Este mês": [moment().startOf("month"), moment().endOf("month")],
    "Mês passado": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  },
  locale: {
    format: "DD/MM/YYYY",
    separator: " até ",
    applyLabel: "Aplicar",
    cancelLabel: "Limpar",
    daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    firstDay: 1,
  },
};

export const ptBrSingle = {
  ...ptBr,
  singleDatePicker: true,
  ranges: {
    "Uma semana": [moment().add(7, "days")],
    "Um mês": [moment().add(1, "month")],
    "6 meses": [moment().add(6, "month")],
    "Um ano": [moment().add(1, "year")],
    "Três anos": [moment().add(3, "year")],
  },
  locale: {
    cancelLabel: "Cancelar",
  },
};
