import {
  creditCardRegex,
  creditCardBrand,
} from "../packs/modules/common/credit-card-regex";

$(document).on("turbolinks:load", () => {
  const setBrandCard = (field, brand) =>
    field
      .removeClass()
      .addClass(`form-control string required credit-card brand ${brand}`);

  const options = {
    onKeyPress: (number, _event, field, options) => {
      const dataBrand = creditCardBrand(number);

      setBrandCard(field, dataBrand?.brand || "default");

      if (!dataBrand) return;

      $(".credit-card").mask(dataBrand.mask, options);
      $(".credit-cvv").mask(dataBrand.maskCvv);
    },
  };

  $(".money").maskMoney();
  $(".zipcode").mask("00000-000");
  $(".cnpj").mask("00.000.000/0000-00");
  $(".cpf").mask("000.000.000-00");
  // $(".phone").mask("(00) 00000-0000");
  $(".credit-card").mask(creditCardRegex.default.mask, options);
  $(".credit-card").on("keydown", (e) => {
    (e.ctrlKey || e.shiftKey) && e.preventDefault();
  });
  $(
    ".credit-name, #user_name, #user_company_name, #promotion_button_text"
  ).mask("#", {
    maxlength: false,
    translation: {
      "#": {
        pattern: /[A-zÀ-ÿ\s]/,
        recursive: true,
      },
    },
  });
});
