$(document).on("turbolinks:load", () => {
  const fieldsAPI = [
    "zip_code",
    "city",
    "state",
    "neighborhood",
    "street",
    "numero",
  ];
  const removeClass = ["is-invalid", "is-valid"];

  const cepUtils = (data, response) => {
    $("#new_address, #nav-user-address, #user-address")
      .find("input")
      .map((_, val) => {
        removeClass.forEach((c) => $(val).removeClass(c));

        fieldsAPI.forEach((val) => {
          const input = $(`input[placeholder='${val}']`);

          if (val === "state" || val === "city") input.prop("readonly", true);

          if (val !== "zip_code" && val !== "numero") {
            input.val(response ? response[val] : "");
          }

          val === "numero" && input.val() === ""
            ? input.addClass("is-invalid")
            : input.addClass(data);
        });
      });
  };

  $("#order_address_number").on("blur change focusout", function () {
    const number = $(this);

    if (number.val() == "") {
      return number.removeClass("is-valid").addClass("is-invalid");
    }

    number.removeClass("is-invalid").addClass("is-valid");
  });

  $("#order_address_zipcode, #user_address_zipcode").on("input", function () {
    const value = $(this).val();

    if (!value || value.length < 8) {
      cepUtils("is-invalid", {});

      $("#new_address")
        .find("input")
        .map((_, val) => $(val).prop("readonly", false));

      return;
    }
  });

  const findAddress = (value) => {
    if (
      fieldsAPI.every((field) =>
        $(`input[placeholder='${field}']`).hasClass("is-valid")
      )
    )
      return;

    $.ajax({
      url: `https://api-core.levanteideias.com.br/api/v1/ceps/${value}`,
      type: "GET",
      dataType: "json",
      cache: true,
      success: (data) => {
        if (data.status_code === 404) return cepUtils("is-invalid");

        return cepUtils("is-valid", data);
      },
      error: () => cepUtils("is-invalid"),
    });
  };

  $("#order_address_zipcode, #user_address_zipcode").on(
    "focusout keyup",
    function (event) {
      const value = $(this).val();

      if (
        event.type === "focusout" ||
        (event.type === "keyup" && event.key === "Enter")
      ) {
        if (value == "") return;

        findAddress(value);
      }
    }
  );
});
