import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import "jquery";
import "jquery-mask-plugin";
import "select2";
import "jquery-ui/ui/widgets/autocomplete.js";
import "./modules/common/jquery-maskmoney.js";
import "daterangepicker/daterangepicker.js";
import "datatables.net-bs5";
import "datetime-moment";
import "bootstrap/dist/js/bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "intl-tel-input/build/css/intlTelInput.css";
import "src/global";
import "src/autocomplete-rails.js";
import "src/masks";
import "src/show_hide_password";
import "src/navbar";
import "src/search_zip";
import "src/toggle_registration_form";
import "src/checkout";
import "src/toggle_credit_card_form";
import "src/random_circles_checkout_finished";
import "src/dropdown_navbar_profile";
import "src/show_hide_search";
import "src/show_modal";
import "src/datatable";
import "src/tracking";
import "src/utm_x";
import "src/admin/bill";
import "src/admin/logs";
import "src/admin/daterangepicker";
import "src/admin/coupon";
import "src/admin/promotion";
import "src/admin/product";
import "src/sidebar";
import "src/tooltip";
import "src/intlTelInput";

require.context("../images", true);

const images = require.context("../images", true);
