$(document).on("turbolinks:load", () => {
  const circles = [
    "checkout-finished-circles-lg",
    "checkout-finished-circles-md",
    "checkout-finished-circles-sm",
  ];

  const randomArray = (arr) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };

  const getRandomArbitrary = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const randomizePosition = (element) => {
    $(element).css({
      left: Math.random() * window.innerWidth,
      top: Math.random() * window.innerHeight,
    });
  };

  for (let start = 0; start < getRandomArbitrary(7, 12); start++) {
    $("#checkout-finished-bg").append(
      `<div class='${randomArray(circles)}'></div>`
    );
  }

  $(".checkout-finished-circles-lg").each((_index, element) =>
    randomizePosition(element)
  );

  $(".checkout-finished-circles-md").each((_index, element) =>
    randomizePosition(element)
  );

  $(".checkout-finished-circles-sm").each((_index, element) =>
    randomizePosition(element)
  );
});
