$(document).on("turbolinks:load", () => {
  $("#nav-app .navbar-nav a").on("click", function () {
    $("#nav-app .navbar-nav").find("a.active").removeClass("active");
    $(this).addClass("active");

    sessionStorage.setItem("activeNav", $(this).attr("href"));
    sessionStorage.ClassName = "active";
  });

  function SetNavClass() {
    let activeTab = sessionStorage.getItem("activeNav");
    let homeLink = $("#nav-app .navbar-nav a#home");
    let loggoutLink = $("#nav-app .navbar-nav .dropdown ul a#loggout");

    if (activeTab == null) {
      $("#home").addClass("active");
    }

    if (activeTab) {
      $("#nav-app .navbar-nav").find("a.active").removeClass("active");
      $(`#nav-app a[href="${activeTab}"]`).addClass(sessionStorage.ClassName);
    }

    if (loggoutLink.hasClass("active")) {
      loggoutLink.removeClass("active");
      homeLink.addClass("active");
    }
  }

  $(document).ready(function () {
    SetNavClass();
  });
});
