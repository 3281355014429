$(document).on("turbolinks:load", () => {
  const fetchAPILogs = (id, callback) => {
    const value = $("#descriptionFilter").val() || "";
    // @TODO: make object stringfy parser
    // Example:
    //   {
    //     description_cont: "FOO",
    //     kind_eq: "BAR"
    //   }

    $.ajax({
      url: `/admin/api/logs/${id}?description_cont=${value}`,
      type: "GET",
      cache: true,
      success: (data) => callback(data),
    });
  };

  // trick to embed the modal in the body element
  const toggleModal = (data) => {
    const modalHtml = $(data);

    return $("#logsModal").length == 0
      ? $(modalHtml).modal("toggle") && $("body").append(modalHtml)
      : $("#logsModal").modal("toggle");
  };

  const replaceTableModel = (data) => {
    const table = $(data).find("#log-table");
    const targetModal = $("#logsModal");

    targetModal.find(".modal-body #log-table").replaceWith(table);
    targetModal.modal("show");
  };

  $(".admin .show-logs").on("click", (e) => {
    const id = $(e.currentTarget).attr("id");

    fetchAPILogs(id, (data) => {
      toggleModal(data);

      $(".search-logs").on("click", () => {
        fetchAPILogs(id, (data) => replaceTableModel(data));
      });
    });
  });

  $(document).on("hidden.bs.modal", "#logsModal", () => {
    $("#logsModal").remove();
  });
});
