import { setUrl, getUrl } from "../packs/modules/common/url";

$(document).on("turbolinks:load", () => {
  /** Exibe form para cadastrar novo cartão de crédito */
  $("#show-credit-card-form-button").on("click", (e) => {
    e.preventDefault();

    $("#new_credit_card").removeClass("d-none");
    $("#new_credit_card").find("input, select").removeClass("is-invalid");
    $("#has_credit_card").addClass("d-none");
    $("#order_use_new_card").val(1);

    setUrl("method", "new_credit_card");
  });

  if (getUrl("method") == "pix") setUrl("splits", "1x");

  $(".new_order #order_payment_method").val(
    getUrl("method") == "pix" ? "pix" : "credit_card"
  );

  /** Temporariamente desabilitado  */
  $("#hide-credit-card-form-button").on("click", (e) => {
    e.preventDefault();

    $("#new_credit_card").addClass("d-none");
    $("#has_credit_card").removeClass("d-none");
    $("#order_use_new_card").val(0);

    setUrl("method", "has_credit_card");
  });

  /** Seta o payment_method PIX ao clicar no collapse  */
  $("#headingPix").on("click", () => {
    setUrl("method", "pix");

    $("#new_credit_card .is-invalid").each((_, a) =>
      $(a).removeClass("is-invalid")
    );

    $("#send-payment-submit-button").val("Efetuar pagamento");
    $("select#order_splits").val("1x").trigger("change");
  });

  /** Seta o payment_method CARD ao clicar no collapse  */
  $("#headingCard").on("click", () => {
    const checkCard = $("#order_use_new_card").val();
    const type = checkCard == "1" ? "new_credit_card" : "has_credit_card";

    setUrl("method", type);

    $("#send-payment-submit-button").val("Confirmar compra");
    $("select#order_splits").val("1x").trigger("change");
  });
});
