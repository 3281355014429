export const setUrl = (key, value) => {
  const url = new URL(window.location.href);

  url.searchParams.set(key, value);
  window.history.replaceState(null, null, url);

  return true;
};

export const getUrl = (key) => {
  const url = new URL(window.location.href);

  return url.searchParams.get(key);
};
