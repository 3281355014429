$(document).on("turbolinks:load", () => {
  $(".password-show").append("<i class='password-icon bi-eye-slash'></i>");

  $("#show_hide_password i").on("click", function (event) {
    event.preventDefault();

    if ($("#show_hide_password input").attr("type") == "text") {
      $("#show_hide_password input").attr("type", "password");
      $("#show_hide_password i").removeClass("bi-eye");
      $("#show_hide_password i").addClass("bi-eye-slash");
    } else if ($("#show_hide_password input").attr("type") == "password") {
      $("#show_hide_password input").attr("type", "text");
      $("#show_hide_password i").removeClass("bi-eye-slash");
      $("#show_hide_password i").addClass("bi-eye");
    }
  });
});
