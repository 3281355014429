$(document).on("turbolinks:load", () => {
  function resolveX() {
    var kvEmail = "";

    //resolve x
    var queryDict = {};
    location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        queryDict[item.split("=")[0]] = item.split("=")[1];
      });

    if (queryDict.utm_x != undefined && queryDict.utm_x != null) {
      var kvArray = CryptoJS.enc.Base64.parse(
        decodeURIComponent(queryDict.utm_x)
      );
      kvEmail = kvArray.toString(CryptoJS.enc.Utf8);
      _etmc.push([
        "setUserInfo",
        {
          email: kvEmail,
        },
      ]);
    }
  }

  $(document).ready(function () {
    resolveX();
  });
});
