$(document).on("turbolinks:load", () => {
  $("#nav-search").hide();

  $(".open-search").on("click", function (event) {
    event.preventDefault();
    $("#nav-app").hide();
    $("#nav-app-mobile").hide();
    $("#nav-search").show();
    $("#query").focus();
  });

  $("#close-search").on("click", function (event) {
    event.preventDefault();
    $("#nav-search").hide();
    $("#nav-app").show();
    $("#nav-app-mobile").show();
  });
});
