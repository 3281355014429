$(document).on("turbolinks:load", () => {
  $("#promotion_button").on("change", function () {
    const checkbox = $(this);
    const buttonDetails = $("#display_promotion_button");

    checkbox.is(":checked")
      ? buttonDetails.removeClass("d-none") && checkbox.addClass("is-valid")
      : buttonDetails.addClass("d-none") && checkbox.removeClass("is-valid");
  });
});
