$(document).on("turbolinks:load", () => {
  const { pathname } = location;
  const [_, __, ___, productId, action] = pathname.split("/");

  $(`nav a[href='${pathname}']`).addClass("active");
  $(`nav a[href*="${productId}/${action}"]`).addClass("active");

  const wrapperSubmenu = $(".sub-item .active")
    .parent()
    .parent()
    .parent()
    .parent();

  wrapperSubmenu.find(".collapsed").attr("aria-expanded", "true");
  wrapperSubmenu.find(".collapsed").removeClass("collapsed");
  wrapperSubmenu.find(".collapse").addClass("show");

  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("tag");

  if (path) {
    $(`.hover-scroll-x a[href*='${path}']`).addClass("btn-primary");
  }

  const activeLinkTop = $("nav.sidebar li>a.active")?.offset()?.top;

  if (activeLinkTop > $(window).height()) {
    $("nav.sidebar").animate(
      {
        scrollTop: activeLinkTop,
      },
      1500
    );
  }
});
