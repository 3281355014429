import { ptBr, ptBrSingle } from "../../packs/modules/common/datepickerlocale";

$(document).on("turbolinks:load", () => {
  const inputDateFormat = ({ startDate, endDate }) =>
    `${startDate.format("DD/MM/YYYY")} até ${endDate.format("DD/MM/YYYY")}`;

  const cacheOptionSelected = (data, input) => {
    let cached = data;

    const paramInputValue = input.val();
    const blankByDefault = input.data("blank-by-default");

    if (blankByDefault && !paramInputValue) {
      cached.autoUpdateInput = false;
    } else if (paramInputValue) {
      const [startDate, endDate] = paramInputValue.split(" até ");
      const startDateMoment = moment(startDate, "DD/MM/YYYY");
      const endDateMoment = moment(endDate, "DD/MM/YYYY");

      cached = {
        ...data,
        startDate: startDateMoment,
        endDate: endDateMoment,
      };

      input.val(
        inputDateFormat({ startDate: startDateMoment, endDate: endDateMoment })
      );
    }

    return cached;
  };

  $(".range-datepicker").each(function () {
    const input = $(this).find("input");

    $(this).on("cancel.daterangepicker", () => input.val(""));

    $(this).on("apply.daterangepicker", (_, { startDate, endDate }) =>
      input.val(inputDateFormat({ startDate, endDate }))
    );

    const options = cacheOptionSelected(ptBr, input);

    input.daterangepicker(options, (startDate, endDate) =>
      input.val(inputDateFormat({ startDate, endDate }))
    );
  });

  $(".datepicker").daterangepicker(ptBrSingle, (startDate, _) => {
    $(".datepicker").find("input").val(startDate.format("DD/MM/YYYY"));
  });
});
