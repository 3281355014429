import { getUrl, setUrl } from "../packs/modules/common/url";
import {
  creditCardBrand,
  creditCardLuhnCheck,
} from "../packs/modules/common/credit-card-regex";

$(document).on("turbolinks:load", () => {
  const fieldsForm = {
    creditCard:
      "#order_cc_number, #order_cc_name, #order_cc_cvv, #order_cc_month, #order_cc_year",
    address:
      "#order_address_zipcode, #order_address_street, #order_address_state, #order_address_number, #order_address_neighborhood, #order_address_city",
  };

  const checkoutInputIds = $(`${fieldsForm.creditCard}, ${fieldsForm.address}`);

  $(fieldsForm.creditCard).on("change", function () {
    if ($(this).hasClass("is-invalid")) $(this).removeClass("is-invalid");
  });

  checkoutInputIds.on("blur keypress focus", () => verifyCheckoutSubmit());

  $("#save-address-button").on("click", (e) => {
    e.preventDefault();

    const city = $("#order_address_city").val();
    const state = $("#order_address_state").val();
    const number = $("#order_address_number").val();
    const address = $("#order_address_street").val();
    const complement = $("#order_address_complement").val();
    const neighborhood = $("#order_address_neighborhood").val();

    $(".address_street").text(`${address}, ${number}`);
    $(".address_city").text(`${neighborhood} - ${city} - ${state}`);
    $(".address_complement").text(complement);

    $("#new_address").addClass("d-none");
    $("#has_address").removeClass("d-none");
    $(".step-address").addClass("active");
  });

  $("select#order_splits").each(function () {
    const split = $(this);

    split.removeClass("is-valid");
    split.on("change", () => updateSplits(split));
  });

  $("#change-address-button").on("click", (e) => {
    e.preventDefault();

    $("#order_address_zipcode").blur();
    $("#has_address").addClass("d-none");
    $("#new_address").removeClass("d-none");
  });

  $("#has_credit_card, #new_credit_card").each(function () {
    if (getUrl("method") == "pix") {
      $("button[data-target='#collapseCard']").attr("aria-expanded", false);
      $("button[data-target='#collapsePix']").attr("aria-expanded", true);
      $("#collapseCard").removeClass("show").attr("aria-expanded", false);
      $("#collapsePix").addClass("show").attr("aria-expanded", true);
      return;
    }

    $("button[data-target='#collapseCard']").attr("aria-expanded", true);
    $("button[data-target='#collapsePix']").attr("aria-expanded", false);
    $("#collapseCard").addClass("show").attr("aria-expanded", true);
    $("#collapsePix").removeClass("show").attr("aria-expanded", false);

    if (!$(this).hasClass("d-none")) setUrl("method", $(this).attr("id"));
  });

  $("#send-payment-submit-button").on("mouseover", function () {
    const alert = $(".alert-checkout");

    alert.fadeTo(2000, 500).slideUp(500, () => alert.slideUp(500));
  });

  $("#new_credit_card select option").each(function () {
    if ($(this).val() === "") $(this).prop("disabled", true);
  });

  const addErrors = (element) => {
    $(".is-invalid").first().trigger("focus");

    const elementDiv = element.parent();
    const findError = elementDiv.find(".invalid-feedback");

    if (findError.length === 0) {
      elementDiv.append(
        `<p class="invalid-feedback">Verifique este campo.</p>`
      );
    }
  };

  const addInvalidClassInputs = (elements) => {
    elements.map(({ condition, element }) => {
      condition
        ? element.addClass("is-invalid") && addErrors(element)
        : element.removeClass("is-invalid");
    });
  };

  const verifyNameCard = (element) => {
    const name = element.val().split(" ");

    return name.length >= 2;
  };

  const verifyCardDateValidation = (elements) => {
    const [ccMonth, ccYear] = elements;

    const creditCardDate = moment()
      .set("year", `20${ccYear.val()}`)
      .set("month", ccMonth.val() - 1);

    const now = moment();
    const end = moment(creditCardDate);
    const duration = moment.duration(now.diff(end));
    const days = duration.asDays();

    if (days > 1 || ccMonth.val() === null || ccYear.val() === null) {
      ccMonth.addClass("is-invalid");
      ccYear.addClass("is-invalid");

      addErrors(ccMonth);
      addErrors(ccYear);

      return false;
    }

    ccMonth.removeClass("is-invalid");
    ccYear.removeClass("is-invalid");

    return true;
  };

  const validateCreditCardForm = () => {
    $("#has_credit_card").addClass("d-none");
    $("#new_credit_card").removeClass("d-none");

    const ccNumber = $("#order_cc_number");
    const ccName = $("#order_cc_name");
    const ccCvv = $("#order_cc_cvv");
    const ccMonth = $("#order_cc_month");
    const ccYear = $("#order_cc_year");

    const lengthCard =
      creditCardBrand(ccNumber.val())?.brand === "amex" ? 4 : 3;
    const validationCreditCard = creditCardLuhnCheck(ccNumber.val());
    const validDate = verifyCardDateValidation([ccMonth, ccYear]);

    addInvalidClassInputs([
      {
        condition: validationCreditCard !== true,
        element: ccNumber,
      },
      {
        condition: ccCvv.val().length != lengthCard,
        element: ccCvv,
      },
      {
        condition: verifyNameCard(ccName) !== true,
        element: ccName,
      },
    ]);

    if (
      ccNumber.val().length > 1 &&
      ccName.val().length > 1 &&
      ccCvv.val().length === lengthCard &&
      validDate &&
      validationCreditCard
    ) {
      return true;
    }

    return false;
  };

  const disableInputButtons = () => {
    setTimeout(() => {
      $("select, input, .btn, a").each((_, val) =>
        $(val).attr("disabled", true)
      );
    }, 0);
  };

  const validateFormCheckout = () => {
    return getUrl("method") === "new_credit_card"
      ? validateCreditCardForm()
      : true; // @TODO check validate address
  };

  $("#order_coupon").on("input", function () {
    $(this).removeClass("is-invalid");
  });

  function updateSplits(split) {
    const coupon = $("#order_coupon").val();
    const offer_slug_id = $("#order_slug_id").val();
    const splits = split.val();

    setUrl("splits", splits);

    $.ajax({
      url: `/levante/apply_coupons/${offer_slug_id}${window.location.search}`,
      method: "POST",
      dataType: "script",
      data: {
        coupon,
        splits,
        split_without_alert: true,
      },
    });

    split.find("option").removeAttr("selected");
    split.find(`option[value='${splits}']`).attr("selected", "selected");
  }

  function validateAddressesForm() {
    const addressInputs = $(fieldsForm.address).filter(
      (_, value) => $(value).val().length === 0
    ).length;

    if (addressInputs === 0) return true;

    return false;
  }

  function verifyCheckoutSubmit() {
    const checkoutSubmitButton = $("#send-payment-submit-button, #btn-fixo");

    if (validateAddressesForm()) {
      checkoutSubmitButton.removeClass("button-disabled");
      checkoutSubmitButton.addClass("button-primary");
      checkoutSubmitButton.attr("disabled", false);
      // $("#save-address-button").attr("disabled", false);
    } else {
      checkoutSubmitButton.removeClass("button-primary");
      checkoutSubmitButton.addClass("button-disabled");
      checkoutSubmitButton.attr("disabled", true);
      // $("#save-address-button").attr("disabled", true);
    }
  }

  $("button[aria-expanded]").on("click", function () {
    $("#order_payment_method").val($(this).val());
  });

  $("#send-payment-submit-button").on("click", function (event) {
    if (!validateFormCheckout()) return event.preventDefault();

    $("#order_v").val(getUrl("v") || 1);

    $(this).val("Processando, aguarde...");

    const formOne = $("#has_credit_card");
    const formTwo = $("#new_credit_card");

    formOne.hasClass("d-none")
      ? formOne.find("#order_splits").remove()
      : formTwo.find("#order_splits").remove();

    disableInputButtons();

    $(".step-payment").addClass("active");
  });

  verifyCheckoutSubmit();

  $(".pix-btn-code").on("click", function () {
    const alert = $(".alert-pix");

    navigator.clipboard.writeText($("#key-pix").val()).then(
      () => {
        alert.addClass("alert-success");
        alert.text("Chave PIX copiada para a área de transferência.");
      },
      () => {
        alert.addClass("alert-danger");
        alert.text("Erro ao copiar o código");
      }
    );

    alert.fadeTo(2000, 500).slideUp(500, () => alert.slideUp(500));
  });

  $("#btn-fixo").on("click", () => {
    $("#send-payment-submit-button").trigger("click");
  });
});
