$(document).on("turbolinks:load", () => {
  $(() => {
    const { pathname } = location;

    if (pathname === "/users")
      $(".step_one_authentication a.btn.button-success").trigger("click");

    if (pathname === "/users/sign_in") {
      $(".step_one_authentication #user_email")
        .removeClass("is-valid")
        .addClass("is-invalid");

      $(".step_one_authentication .btn-account .login a").trigger("click");
    }
  });

  const resetFormButtons = () => {
    $("div#step_one_authentication").each(function () {
      $(this).hide();

      $(".btn-account").each(function () {
        $(this).show();
      });
    });
  };

  $(".btn-account a").each(function () {
    $(this).on("click", function () {
      resetFormButtons();

      const div = $(this).parent().parent().parent();

      div.find("#step_one_authentication").show();
      div.find(".btn-account").hide();
    });
  });

  const resetFormRegistrations = (one, two) => {
    one.addClass("d-none");
    one.find("input").val("");
    two.removeClass("d-none");
  };

  $("#user_is_cnpj").on("change", function () {
    const formPj = $("#register_pf");
    const formPf = $("#register_pj");

    if ($(this).is(":checked")) {
      resetFormRegistrations(formPj, formPf);
    } else {
      resetFormRegistrations(formPf, formPj);
    }
  });
});
