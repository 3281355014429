$(document).on("turbolinks:load", () => {
  $("#dropdown-navbar-profile").on("mouseenter", function () {
    $(this).attr("aria-expanded", "true");
    $(this).addClass("show");
    $("#menu-drop").attr("data-bs-popper", "true");
    $("#menu-drop").addClass("dropdown-menu-end me-3 show");
  });

  $("#menu-drop").on("mouseleave", function () {
    $("#dropdown-navbar-profile").attr("aria-expanded", "false");
    $("#dropdown-navbar-profile").removeClass("show");
    $("#menu-drop").removeClass("dropdown-menu-end me-3 show");
  });
});
